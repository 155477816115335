require('./bootstrap');

let prevScrollpos = window.pageYOffset;

require('bootbox');

$(function () {

    $('[data-href]').on('click', function (e) {
        e.preventDefault();
        if($(this).data('target')){
            window.open($(this).data('href'), $(this).data('target'));
        } else {
            window.location = $(this).data('href');
        }
    });

    $('[data-sneak]').on('click', function(e){
        e.preventDefault();
        let $input = $(this.dataset.sneak),
            $el    = $(this),
            type   = $input.attr('type') === 'password' ? 'text' : 'password';
        $input.attr('type', type);
        $('i', $el).toggleClass('fa-eye-slash fa-eye');
        $input.focus()
    });

    if($('#notify').length){

        setTimeout(function(){
            $('#notify').addClass('show');
            setTimeout(function(){$('#notify').removeClass('show');}, 4000 );
        }, 500 );
    }

/*
    window.onscroll = function() {

        let  currentScrollPos = window.pageYOffset;

        if (prevScrollpos > currentScrollPos) {
            document.getElementById("nav").style.bottom = "0";
        } else {
            document.getElementById("nav").style.bottom = "-100px";
        }

        prevScrollpos = currentScrollPos;
    };
*/





});



